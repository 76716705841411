import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  constructor(private _translateService: TranslateService) {
    super();
    this.translateLabels();
    this._translateService.onLangChange.subscribe(() => {
      this.translateLabels();
      this.changes.next();
    });
  }

  translateLabels() {
    this.itemsPerPageLabel = this._translateService.instant('Items per page:');
    // this.nextPageLabel = this._translateService.instant('Next');
    // this.previousPageLabel = this._translateService.instant('Previous');
  }

  // override getRangeLabel = (page: number, pageSize: number, length: number): string => {
  //   if (length === 0 || pageSize === 0) {
  //     return this._translateService.instant('0 of {length}', {length});
  //   }
  //   const startIndex = page * pageSize;
  //   const endIndex = Math.min(startIndex + pageSize, length);
  //   return this._translateService.instant('{start} - {end} of {length}', {
  //     start: startIndex + 1,
  //     end: endIndex,
  //     length,
  //   });
  // };
}
